const appendEcommerceTestVariation = (variation) => {
  const trackingData = window.tracking_data;
  if (!trackingData) return;

  const existingVariation = trackingData.ecommerce_test_variation;
  // TODO: Remove this. Right now Getty Search Bar loads three time and we need to figure out why.
  // because of that, it appends three of the same strings onto the tracking data

  if (existingVariation) {
    if (existingVariation.includes(variation)) return;
    trackingData.ecommerce_test_variation = `${existingVariation}|${variation}`;
  } else {
    trackingData.ecommerce_test_variation = variation;
  }
};

export default appendEcommerceTestVariation;
